import React from 'react'

function SiteCanvas(props) {

  function openEmail(e) {
    e.preventDefault()
    var form = e.target
    var name = form[0].value
    var lastName = form[1].value
    var email = form[2].value
    var comment = form[3].value

    if(!name || !lastName || !email || !comment){
      alert('Please fill all fields to submit the form')
      return;
    }
    window.open(`mailto:tvlcoin.org@gmail.com?subject=contact-form&body=${comment}`);
  }

  return (
    <div>
      <div className="banner-wrap">
        <div className="wsite-elements wsite-not-footer wsite-header-elements">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-header-section wsite-section-bg-image" style={{"height":"727px","background-image":"url(\"./images/1389374582.png\")","background-repeat":"no-repeat","background-position":"50% 50%","background-size":"cover","background-color":"transparent"}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="banner">
                    <div className="wsite-section-elements">
                    </div>
                  </div>
                </div>
              </div>
              <div className />
            </div>
          </div>
        </div>
      </div>
      <div className="main-wrap">
        <div id="wsite-content" className="wsite-elements wsite-not-footer">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-section-bg-color wsite-background-8" style={{"height":"auto","background-color":"#ffffff","background-image":"none","is_customized":"1"}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}>CONTACT US</h2>
                    <div id={392077034794720693}>
                      <div>
                        <div id="element-b40a5355-5082-4def-a675-26bba3fad2e2" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                          <div className="colored-box">
                            <div className="colored-box-content">
                              <div style={{"width":"auto"}}>
                                <div />
                                <div>
                                  <form encType="multipart/form-data" onSubmit={ e => openEmail(e)} method="POST" id="form-528761389767335492">
                                    <div id="528761389767335492-form-parent" className="wsite-form-container" style={{"margin-top":"10px"}}>
                                      <ul className="formlist" id="528761389767335492-form-list">
                                        <label className="wsite-form-label wsite-form-fields-required-label"><span className="form-required">*</span> Indicates required field</label>
                                        <div>
                                          <div className="wsite-multicol">
                                            <div className="wsite-multicol-table-wrap" style={{"margin":"0 -15px"}}>
                                              <table className="wsite-multicol-table">
                                                <tbody className="wsite-multicol-tbody">
                                                  <tr className="wsite-multicol-tr">
                                                    <td className="wsite-multicol-col" style={{"width":"28.260869565217%","padding":"0 15px"}}>
                                                      <div>
                                                        <div className="wsite-form-field" style={{"margin":"5px 0px 0px 0px"}}>
                                                          <label className="wsite-form-label" htmlFor="input-434498268482836047">First Name <span className="form-required">*</span></label>
                                                          <div className="wsite-form-input-container">
                                                            <input aria-required="true" id="input-434498268482836047" className="wsite-form-input wsite-input wsite-input-width-200px" type="text" name="_u434498268482836047" />
                                                          </div>
                                                          <div id="instructions-434498268482836047" className="wsite-form-instructions" style={{"display":"none"}} />
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td className="wsite-multicol-col" style={{"width":"71.739130434783%","padding":"0 15px"}}>
                                                      <div>
                                                        <div className="wsite-form-field" style={{"margin":"5px 0px 0px 0px"}}>
                                                          <label className="wsite-form-label" htmlFor="input-267501614738647735">Last Name <span className="form-required">*</span></label>
                                                          <div className="wsite-form-input-container">
                                                            <input aria-required="true" id="input-267501614738647735" className="wsite-form-input wsite-input wsite-input-width-200px" type="text" name="_u267501614738647735" />
                                                          </div>
                                                          <div id="instructions-267501614738647735" className="wsite-form-instructions" style={{"display":"none"}} />
                                                        </div>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="wsite-form-field" style={{"margin":"5px 0px 5px 0px"}}>
                                            <label className="wsite-form-label" htmlFor="input-810499646785371471">Email Address <span className="form-required">*</span></label>
                                            <div className="wsite-form-input-container">
                                              <input aria-required="true" id="input-810499646785371471" className="wsite-form-input wsite-input wsite-input-width-370px" type="text" name="_u810499646785371471" />
                                            </div>
                                            <div id="instructions-810499646785371471" className="wsite-form-instructions" style={{"display":"none"}} />
                                          </div>
                                        </div>
                                        <div className="wsite-spacer" style={{"height":"10px"}} />
                                        <div>
                                          <div className="wsite-form-field" style={{"margin":"5px 0px 5px 0px"}}>
                                            <label className="wsite-form-label" htmlFor="input-977562103357284084">Comment <span className="form-required">*</span></label>
                                            <div className="wsite-form-input-container">
                                              <textarea aria-required="true" id="input-977562103357284084" className="wsite-form-input wsite-input wsite-input-width-370px" name="_u977562103357284084" style={{"height":"200px"}} defaultValue={""} />
                                            </div>
                                            <div id="instructions-977562103357284084" className="wsite-form-instructions" style={{"display":"none"}} />
                                          </div>
                                        </div>
                                      </ul>
                                    </div>
                                    <div style={{"display":"none","visibility":"hidden"}}>
                                      <input type="hidden" name="wsite_subject" />
                                    </div>
                                    <div style={{"-webkit-text-align":"left","text-align":"left","margin-top":"10px","margin-bottom":"10px"}}>
                                      <input type="hidden" name="form_version" defaultValue={2} />
                                      <input type="hidden" name="wsite_approved" id="wsite-approved" defaultValue="approved" />
                                      <input type="hidden" name="ucfid" defaultValue={528761389767335492} />
                                      <input type="hidden" name="recaptcha_token" />
                                      <input type="submit" role="button" aria-label="Submit" defaultValue="Submit" style={{"position":"absolute","top":"0","left":"-9999px","width":"1px","height":"1px"}} />
                                      <button className="wsite-button" type='submit'>
                                        <span className="wsite-button-inner">Submit</span>
                                      </button>
                                    </div>
                                  </form>
                                  <div id="g-recaptcha-528761389767335492" className="recaptcha" data-size="invisible" data-recaptcha={1} data-sitekey="6Ldf5h8UAAAAAJFJhN6x2OfZqBvANPQcnPa8eb1C" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{"clear":"both"}} />
                      </div>
                    </div>
                    <div className="wsite-spacer" style={{"height":"24px"}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SiteCanvas