import SiteCanvas from './SiteCanvas'
import React, { useEffect } from 'react'
import SiteWrapper from '../../components/siteWrapper';

function AboutUS(props) {
  
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [])

  return(
    <SiteWrapper>
      <SiteCanvas />
    </SiteWrapper>
  )
}

export default AboutUS