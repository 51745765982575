import React, {useEffect} from 'react'

function SiteCanvas(props) {

  useEffect(() => {
    window.wSlideshow && window.wSlideshow.render({elementID:"766431785217092377",nav:"none",navLocation:"bottom",captionLocation:"bottom",transition:"fade",autoplay:"1",speed:"5",aspectRatio:"4:3",showControls:"false",randomStart:"false",images:[{"url":"shutterstock-295260155.jpg","width":"800","height":"534"},{"url":"shutterstock-2110507193.jpg","width":"800","height":"532"},{"url":"shutterstock-301388048.jpg","width":"800","height":"533"},{"url":"shutterstock-603198743.jpg","width":"800","height":"533"}]})
    window.wSlideshow && window.wSlideshow.render({elementID:"343086632957971897",nav:"none",navLocation:"bottom",captionLocation:"bottom",transition:"fade",autoplay:"1",speed:"5",aspectRatio:"4:3",showControls:"false",randomStart:"false",images:[{"url":"shutterstock-634733486.jpg","width":"800","height":"662"},{"url":"shutterstock-331139660.jpg","width":"800","height":"533"},{"url":"shutterstock-569765104.jpg","width":"800","height":"550"}]})
    window.wSlideshow && window.wSlideshow.render({elementID:"955020723539487353",nav:"none",navLocation:"bottom",captionLocation:"bottom",transition:"fade",autoplay:"1",speed:"5",aspectRatio:"4:3",showControls:"false",randomStart:"false",images:[{"url":"shutterstock-549133747.jpg","width":"800","height":"533"},{"url":"shutterstock-675395998.jpg","width":"800","height":"800"},{"url":"shutterstock-421227052.jpg","width":"800","height":"533"},{"url":"shutterstock-2101518955.jpg","width":"800","height":"800"}]})
    window.wSlideshow && window.wSlideshow.render({elementID:"870133056973277776",nav:"none",navLocation:"bottom",captionLocation:"bottom",transition:"fade",autoplay:"1",speed:"5",aspectRatio:"4:3",showControls:"false",randomStart:"false",images:[{"url":"shutterstock-1564001776.jpg","width":800,"height":533,"fullHeight":733,"fullWidth":1100},{"url":"shutterstock-705012406.jpg","width":800,"height":533,"fullHeight":733,"fullWidth":1100},{"url":"shutterstock-1511929781.jpg","width":800,"height":533,"fullHeight":733,"fullWidth":1100}]})
    window.wSlideshow && window.wSlideshow.render({elementID:"753082435734720840",nav:"none",navLocation:"bottom",captionLocation:"bottom",transition:"fade",autoplay:"1",speed:"5",aspectRatio:"4:3",showControls:"false",randomStart:"false",images:[{"url":"shutterstock-1427173220.jpg","width":"800","height":"533"},{"url":"shutterstock-497557876.jpg","width":"800","height":"533"},{"url":"shutterstock-625264535.jpg","width":"800","height":"533"},{"url":"shutterstock-330835244.jpg","width":"800","height":"533"}]})
    window.wSlideshow && window.wSlideshow.render({elementID:"594034449989735100",nav:"none",navLocation:"bottom",captionLocation:"bottom",transition:"fade",autoplay:"1",speed:"5",aspectRatio:"4:3",showControls:"false",randomStart:"false",images:[{"url":"shutterstock-82781302.jpg","width":800,"height":533,"fullHeight":733,"fullWidth":1100},{"url":"shutterstock-95409589.jpg","width":800,"height":524,"fullHeight":721,"fullWidth":1100},{"url":"shutterstock-227044273.jpg","width":800,"height":533,"fullHeight":733,"fullWidth":1100},{"url":"shutterstock-433185547.jpg","width":800,"height":533,"fullHeight":733,"fullWidth":1100}]})
    window.wSlideshow && window.wSlideshow.render({elementID:"654179660993914080",nav:"none",navLocation:"bottom",captionLocation:"bottom",transition:"fade",autoplay:"1",speed:"5",aspectRatio:"4:3",showControls:"false",randomStart:"false",images:[{"url":"shutterstock-88610386.jpg","width":"800","height":"533"},{"url":"shutterstock-2073635288.jpg","width":"800","height":"533"},{"url":"shutterstock-258367022.jpg","width":"800","height":"538"}]})
    window.wSlideshow && window.wSlideshow.render({elementID:"773382945647618815",nav:"none",navLocation:"bottom",captionLocation:"bottom",transition:"fade",autoplay:"1",speed:"5",aspectRatio:"4:3",showControls:"false",randomStart:"true", images:[{"url":"shutterstock-135763049.jpg","width":"800","height":"533"},{"url":"shutterstock-1697843746.jpg","width":"800","height":"451"},{"url":"shutterstock-106048154.jpg","width":"251","height":"207"}]})
    window.wSlideshow && window.wSlideshow.render({elementID:"353526164714557898",nav:"none",navLocation:"bottom",captionLocation:"bottom",transition:"fade",autoplay:"1",speed:"5",aspectRatio:"4:3",showControls:"false",randomStart:"false",images:[{"url":"shutterstock-175622708.jpg","width":"800","height":"533"},{"url":"shutterstock-2105826329.jpg","width":"800","height":"422"},{"url":"shutterstock-1674337942.jpg","width":"800","height":"533"}]})
  }, []);
  

  return (
    <div>
      <div className="banner-wrap">
        <div className="wsite-elements wsite-not-footer wsite-header-elements">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-header-section wsite-section-bg-image" style={{"height":"739px","background-image":"url(\"./images/1389374582.png\")","background-repeat":"no-repeat","background-position":"50% 50%","background-size":"cover","background-color":"transparent"}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="banner">
                    <div className="wsite-section-elements">
                      <h2 className="wsite-content-title">NFT MARKETPLACE</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className />
            </div>
          </div>
        </div>
      </div>
      <div className="main-wrap">
        <div id="wsite-content" className="wsite-elements wsite-not-footer">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-background-13">
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}>SPORTZ COIN NFT MARKETPLACE</h2>
                    <div className="paragraph"><strong>SPORTZ COIN</strong> NFT marketplace will feature a variety of sport categories with unique collections. &nbsp;Here sports fans will be able to buy, sell, trade and auction sport related NFTs. &nbsp;</div>
                    <div id={469159905587807625}>
                      <div>
                        <div id="element-9fe9d13e-cb97-4c2e-a2fa-32efd428e744" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                          <div className="colored-box">
                            <div className="colored-box-content">
                              <div style={{"width":"auto"}}>
                                <div />
                                <div id={448617855811279067}>
                                  <div>
                                    <div id="element-aca267cf-2e21-49ac-a50b-cf4c61e369e7" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                      <div className="colored-box">
                                        <div className="colored-box-content">
                                          <div style={{"width":"auto"}}>
                                            <div />
                                            <div>
                                              <div className="wsite-image wsite-image-border-none " style={{"paddingTop":"10px","padding-bottom":"10px","margin-left":"0px","margin-right":"0px","-webkit-text-align":"center","text-align":"center"}}>
                                                <a href="http://www.sportznfts.com" target="_blank">
                                                  <img src="./images/screenshot-2021-08-19-at-15-19-37.png" alt="Picture" style={{"width":"auto","max-width":"100%"}} />
                                                </a>
                                                <div style={{"display":"block","font-size":"90%"}} />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{"clear":"both"}} />
                                  </div>
                                </div>
                                <div id={843449285574889058}>
                                  <div>
                                    <div id="element-cbd4e254-c5b7-4c91-808e-a99386290857" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                      <div className="colored-box">
                                        <div className="colored-box-content">
                                          <div style={{"width":"auto"}}>
                                            <div />
                                            <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}>SPORTS CATEGORIES</h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div style={{"clear":"both"}} />
                                  </div>
                                </div>
                                <div className="wsite-spacer" style={{"height":"50px"}} />
                                <div>
                                  <div className="wsite-multicol">
                                    <div className="wsite-multicol-table-wrap" style={{"margin":"0 -15px"}}>
                                      <table className="wsite-multicol-table">
                                        <tbody className="wsite-multicol-tbody">
                                          <tr className="wsite-multicol-tr">
                                            <td className="wsite-multicol-col" style={{"width":"33.333333333333%","padding":"0 15px"}}>
                                              <div id={160126485634208958}>
                                                <div>
                                                  <div id="element-bf4b7a74-b6b9-43c3-911a-b177732c7493" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                    <div className="colored-box">
                                                      <div className="colored-box-content">
                                                        <div style={{"width":"auto"}}>
                                                          <div />
                                                          <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}><font size={5}>ATHLETICS</font></h2>
                                                          <div id={186535331250815611}>
                                                            <div>
                                                              <div id="element-cc361cd9-5dbb-43d9-b3f5-41d5239c344e" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                <div className="colored-box">
                                                                  <div className="colored-box-content">
                                                                    <div style={{"width":"auto"}}>
                                                                      <div />
                                                                      <div>
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                        <div id="766431785217092377-slideshow" />
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div style={{"clear":"both"}} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div style={{"clear":"both"}} />
                                                </div>
                                              </div>
                                              <div className="wsite-spacer" style={{"height":"50px"}} />
                                              <div id={531502000897528016}>
                                                <div>
                                                  <div id="element-47f717c7-0063-4633-9667-e3562a4b0d66" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                    <div className="colored-box">
                                                      <div className="colored-box-content">
                                                        <div style={{"width":"auto"}}>
                                                          <div />
                                                          <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}><font size={5}>INDIVIDUAL</font></h2>
                                                          <div id={803909333407871581}>
                                                            <div>
                                                              <div id="element-99b2a88d-4b56-4ddd-8879-76733655fd46" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                <div className="colored-box">
                                                                  <div className="colored-box-content">
                                                                    <div style={{"width":"auto"}}>
                                                                      <div />
                                                                      <div>
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                        <div id="343086632957971897-slideshow" />
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div style={{"clear":"both"}} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div style={{"clear":"both"}} />
                                                </div>
                                              </div>
                                              <div className="wsite-spacer" style={{"height":"50px"}} />
                                              <div id={671999807641940295}>
                                                <div>
                                                  <div id="element-12fcaa41-3d83-4d16-8543-292dfd508817" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                    <div className="colored-box">
                                                      <div className="colored-box-content">
                                                        <div style={{"width":"auto"}}>
                                                          <div />
                                                          <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}><font size={5}>TEAM</font></h2>
                                                          <div id={873602761293220905}>
                                                            <div>
                                                              <div id="element-c468dc47-fa99-46cb-8943-5df294642f65" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                <div className="colored-box">
                                                                  <div className="colored-box-content">
                                                                    <div style={{"width":"auto"}}>
                                                                      <div />
                                                                      <div>
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                        <div id="955020723539487353-slideshow" />
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div style={{"clear":"both"}} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div style={{"clear":"both"}} />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="wsite-multicol-col" style={{"width":"33.333333333333%","padding":"0 15px"}}>
                                              <div id={531239168343979183}>
                                                <div>
                                                  <div id="element-662394f9-933e-4cf5-98ea-874c3b4ee930" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                    <div className="colored-box">
                                                      <div className="colored-box-content">
                                                        <div style={{"width":"auto"}}>
                                                          <div />
                                                          <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}><font size={5}>ESPORTS</font></h2>
                                                          <div id={747926794114552502}>
                                                            <div>
                                                              <div id="element-1b503f19-2d66-40e0-838d-7b83bc41f2e4" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                <div className="colored-box">
                                                                  <div className="colored-box-content">
                                                                    <div style={{"width":"auto"}}>
                                                                      <div />
                                                                      <div>
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                        <div id="870133056973277776-slideshow" />
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div style={{"clear":"both"}} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div style={{"clear":"both"}} />
                                                </div>
                                              </div>
                                              <div className="wsite-spacer" style={{"height":"50px"}} />
                                              <div id={795353738317785152}>
                                                <div>
                                                  <div id="element-40b50404-f5d8-408f-8cf6-56ed8844cd0d" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                    <div className="colored-box">
                                                      <div className="colored-box-content">
                                                        <div style={{"width":"auto"}}>
                                                          <div />
                                                          <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}><font size={5}>MOTOR</font></h2>
                                                          <div id={990299377344556871}>
                                                            <div>
                                                              <div id="element-8133241e-5180-4de0-b0d0-1bb165e6d07c" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                <div className="colored-box">
                                                                  <div className="colored-box-content">
                                                                    <div style={{"width":"auto"}}>
                                                                      <div />
                                                                      <div>
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                        <div id="753082435734720840-slideshow" />
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div style={{"clear":"both"}} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div style={{"clear":"both"}} />
                                                </div>
                                              </div>
                                              <div className="wsite-spacer" style={{"height":"50px"}} />
                                              <div id={819846153907068176}>
                                                <div>
                                                  <div id="element-189adb0d-8eaa-49c5-9377-6a609c34886d" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                    <div className="colored-box">
                                                      <div className="colored-box-content">
                                                        <div style={{"width":"auto"}}>
                                                          <div />
                                                          <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}><font size={5}>WATER</font></h2>
                                                          <div id={714914454500687223}>
                                                            <div>
                                                              <div id="element-8050d652-376e-415c-92ab-cbee06cbfadf" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                <div className="colored-box">
                                                                  <div className="colored-box-content">
                                                                    <div style={{"width":"auto"}}>
                                                                      <div />
                                                                      <div>
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                        <div id="594034449989735100-slideshow" />
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div style={{"clear":"both"}} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div style={{"clear":"both"}} />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="wsite-multicol-col" style={{"width":"33.333333333333%","padding":"0 15px"}}>
                                              <div id={867553011741239500}>
                                                <div>
                                                  <div id="element-3a0e77d1-4ea2-46e1-9214-bb909fc400e3" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                    <div className="colored-box">
                                                      <div className="colored-box-content">
                                                        <div style={{"width":"auto"}}>
                                                          <div />
                                                          <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}><font size={5}>EXTREME</font></h2>
                                                          <div id={135734509752534272}>
                                                            <div>
                                                              <div id="element-5e45c610-6b90-44b0-8d6f-b275de685d55" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                <div className="colored-box">
                                                                  <div className="colored-box-content">
                                                                    <div style={{"width":"auto"}}>
                                                                      <div />
                                                                      <div>
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                        <div id="654179660993914080-slideshow" />
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div style={{"clear":"both"}} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div style={{"clear":"both"}} />
                                                </div>
                                              </div>
                                              <div className="wsite-spacer" style={{"height":"50px"}} />
                                              <div id={582265769947736115}>
                                                <div>
                                                  <div id="element-7f47cce3-3bcd-4016-a3aa-30c55ae7eaf4" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                    <div className="colored-box">
                                                      <div className="colored-box-content">
                                                        <div style={{"width":"auto"}}>
                                                          <div />
                                                          <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}><font size={5}>STRATEGY</font></h2>
                                                          <div id={101928543619877066}>
                                                            <div>
                                                              <div id="element-41222a1a-e025-4787-95bd-84a1a07bd10a" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                <div className="colored-box">
                                                                  <div className="colored-box-content">
                                                                    <div style={{"width":"auto"}}>
                                                                      <div />
                                                                      <div>
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                        <div id="773382945647618815-slideshow" />
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div style={{"clear":"both"}} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div style={{"clear":"both"}} />
                                                </div>
                                              </div>
                                              <div className="wsite-spacer" style={{"height":"50px"}} />
                                              <div id={195689051295408895}>
                                                <div>
                                                  <div id="element-9bb3cc05-5b04-4701-8818-fa2494a5b665" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                    <div className="colored-box">
                                                      <div className="colored-box-content">
                                                        <div style={{"width":"auto"}}>
                                                          <div />
                                                          <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}><font size={5}>EVENTS</font></h2>
                                                          <div id={707485871533598156}>
                                                            <div>
                                                              <div id="element-6e92dc57-0ef6-4a4b-b9fb-14e68ad5d9a6" data-platform-element-id="848857247979793891-1.0.1" className="platform-element-contents">
                                                                <div className="colored-box">
                                                                  <div className="colored-box-content">
                                                                    <div style={{"width":"auto"}}>
                                                                      <div />
                                                                      <div>
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                        <div id="353526164714557898-slideshow" />
                                                                        <div style={{"height":"10px","overflow":"hidden"}} />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div style={{"clear":"both"}} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div style={{"clear":"both"}} />
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{"clear":"both"}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SiteCanvas