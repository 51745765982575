import React from 'react'
import { NavLink  } from 'react-router-dom'

function Header(props) {
  return(
    <div className="birdseye-header">
      <div className="nav-wrap">
        <div className="container">
          <div className="logo"><span className="wsite-logo">
              <NavLink to={'/'}><span id="wsite-title">SPORTZ COIN</span></NavLink>
            </span>
          </div>
          <div className="nav desktop-nav">
            <ul className="wsite-menu-default">
              <li id="active" className="wsite-menu-item-wrap">
                <NavLink to={'/'} className="wsite-menu-item">Home</NavLink>
              </li>
              <li id="pg790942949228973346" className="wsite-menu-item-wrap">
                <NavLink to={'/nfts'} className="wsite-menu-item">NFTs</NavLink>
              </li>
              <li id="pg843665505770576100" className="wsite-menu-item-wrap">
                <NavLink to={'/nft-marketplace'} className="wsite-menu-item">NFT Marketplace</NavLink>
              </li>
              <li id="pg833058389308694912" className="wsite-menu-item-wrap">
                <NavLink to={'/metaverse'} className="wsite-menu-item">Metaverse</NavLink>
              </li>
              <li id="pg176369378963654487" className="wsite-menu-item-wrap">
                <NavLink to={'/about-us'} className="wsite-menu-item">About us</NavLink>
                <div className="wsite-menu-wrap" style={{"display":"none"}}>
                  <ul className="wsite-menu">
                    <li id="wsite-nav-558242696107272104" className="wsite-menu-subitem-wrap ">
                    <NavLink to={'/contact'} className="wsite-menu-subitem">
                      <span className="wsite-menu-title">Contact</span>
                    </NavLink>
                    </li>
                    <li id="wsite-nav-294597599960804334" className="wsite-menu-subitem-wrap ">
                      <a href="/files/sportzcoin_whitepaper.pdf" target="_blank" className="wsite-menu-subitem">
                        <span className="wsite-menu-title">
                          WHITEPAPER
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <a className="hamburger" aria-label="Menu" href="#"><span /></a>
        </div>
      </div>
    </div>
  )
}

export default Header