import React from 'react'

function SiteCanvas(params) {
  return (
    <div>
      <div className="banner-wrap">
        <div className="wsite-elements wsite-not-footer wsite-header-elements">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-header-section wsite-section-bg-image" style={{"height":"811px","background-image":"url(\"./images/1389374582.png\")","background-repeat":"no-repeat","background-position":"50% 50%","background-size":"cover","background-color":"transparent"}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="banner">
                    <div className="wsite-section-elements">
                    </div>
                  </div>
                </div>
              </div>
              <div className />
            </div>
          </div>
        </div>
      </div>
      <div className="main-wrap">
        <div id="wsite-content" className="wsite-elements wsite-not-footer">
          <div className="wsite-section-wrap">
            <div className="wsite-section wsite-body-section wsite-background-6" style={{"height":"504px"}}>
              <div className="wsite-section-content">
                <div className="container">
                  <div className="wsite-section-elements">
                    <div>
                      <div className="wsite-image wsite-image-border-none " style={{"padding-top":"0px","padding-bottom":"10px","margin-left":"0px","margin-right":"0px","-webkit-text-align":"center","text-align":"center"}}>
                        <a>
                          <img src="./images/screenshot-2021-08-19-at-15-19-37.png" alt="Picture" style={{"width":"auto","max-width":"100%"}} />
                        </a>
                        <div style={{"display":"block","font-size":"90%"}} />
                      </div>
                    </div>
                    <h2 className="wsite-content-title" style={{"-webkit-text-align":"center","text-align":"center"}}>ABOUT CUSTOM SPORTS SOLUTIONS</h2>
                    <div className="paragraph" style={{"-webkit-text-align":"center","text-align":"center"}}>Since 2004 and with access to clients from all sectors including the sports, technology, financial, automotive, food and beverage, travel and tourism industries, our team has been building a strong, reliable, well respected business and global media platform with new technologies that enable companies and organizations around the world to drive sales and raise awareness’s on the responsible, sustainable economic development.<br /><br />Our many years of learning, understanding, gained knowledge, expertise and capabilities, as well as creating strong well respected extensive relationships that provides new benefits and solutions for customers, companies the ability to use, adapt into new easy-to-use platforms.&nbsp;&nbsp;We deliver both customer satisfaction and financial results for the fast growing sports industry needs.&nbsp;<br /><br />Above all, our commitment is to help the sports industry to build, and operate successful marketing programs through use of NFTs, easy to use universal sports currency that allows customers to benefit from the right offers, packages, select the best products, tailored sports deals, promotions, incentives, increased loyalty and save on events from around the globe with rewards.<br /><br /><strong>How NFTs will work for you</strong><br />We will work with existing sports members, athletes, federations, industry team agents to do whatever is necessary to plan, make available and design the best NFTs, for sport fans to benefit from.<br />Our model is to build respected, sustainable and responsible relationships between the sports industry and sport fans adopting to new technologies.<span><span> </span></span></div>
                    <div style={{"-webkit-text-align":"center","text-align":"center"}}>
                      <div style={{"height":"10px","overflow":"hidden"}} />
                      <a className="wsite-button wsite-button-small wsite-button-normal" href="/files/sportzcoin_whitepaper.pdf">
                        <span className="wsite-button-inner">WHITEPAPER</span>
                      </a>
                      <div style={{"height":"10px","overflow":"hidden"}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SiteCanvas