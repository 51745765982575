import React from 'react'
import MobileNav from './MobileNav'
import Header from './Header'
import Footer from './Footer'

function SiteWrapper(props) {
  return(
    <React.Fragment>
      <div className="wrapper">
        <Header />
          {props.children}
        <Footer />
      </div>
      <MobileNav />
    </React.Fragment>
  )
}

export default SiteWrapper