import React from 'react'
import { Link  } from 'react-router-dom'

function Footer(props) {

  return(
    <div className="footer-wrap">
      <div className="footer">
        <div className="wsite-elements wsite-footer">
          <div>
            <div className="wsite-multicol">
              <div className="wsite-multicol-table-wrap" style={{"margin":"0 -15px"}}>
                <table className="wsite-multicol-table">
                  <tbody className="wsite-multicol-tbody">
                    <tr className="wsite-multicol-tr">
                      <td className="wsite-multicol-col" style={{"width":"25%","padding":"0 15px"}}>
                        <div>
                          <div className="wsite-image wsite-image-border-none " style={{"padding-top":"0px","padding-bottom":"0px","margin-left":"0px","margin-right":"0px","-webkit-text-align":"center","text-align":"center"}}>
                            <a href="https://t.me/sportzcoinofficial" target="_blank">
                              <img src="./images/telegram-32_orig.png" alt="Picture" style={{"width":"auto","max-width":"100%"}} />
                            </a>
                            <div style={{"display":"block","font-size":"90%"}} />
                          </div>
                        </div>
                        <div className="wsite-spacer" style={{"height":"10px"}} />
                      </td>
                      <td className="wsite-multicol-col" style={{"width":"25%","padding":"0 15px"}}>
                        <div>
                          <div className="wsite-image wsite-image-border-none " style={{"padding-top":"0px","padding-bottom":"0px","margin-left":"0px","margin-right":"0px","-webkit-text-align":"center","text-align":"center"}}>
                            <a href="http://www.twitter.com/sportzcoin" target="_blank">
                              <img src="./images/twitter-32_orig.png" alt="Picture" style={{"width":"auto","max-width":"100%"}} />
                            </a>
                            <div style={{"display":"block","font-size":"90%"}} />
                          </div>
                        </div>
                      </td>
                      <td className="wsite-multicol-col" style={{"width":"25%","padding":"0 15px"}}>
                        <div>
                          <div className="wsite-image wsite-image-border-none " style={{"padding-top":"0px","padding-bottom":"10px","margin-left":"0px","margin-right":"0px","-webkit-text-align":"center","text-align":"center"}}>
                            <a href="https://github.com/SportzCoinSPZ" target="_blank">
                              <img src="./images/291716-github-logo-social-network-social-icon_orig.png" alt="Picture" style={{"width":"auto","max-width":"100%"}} />
                            </a>
                            <div style={{"display":"block","font-size":"90%"}} />
                          </div>
                        </div>
                      </td>
                      <td className="wsite-multicol-col" style={{"width":"25%","padding":"0 15px"}}>
                        <div>
                          <div className="wsite-image wsite-image-border-none " style={{"padding-top":"0px","padding-bottom":"0px","margin-left":"0px","margin-right":"0px","-webkit-text-align":"center","text-align":"center"}}>
                            <a href="https://bscscan.com/token/0xfc49e2752cb96ad84219c594df7a5383ff729fd8" target="_blank">
                              <img src="./images/bsc.png" alt="Picture" style={{"width":"auto","max-width":"100%"}} />
                            </a>
                            <div style={{"display":"block","font-size":"90%"}} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="wsite-spacer" style={{"height":"13px"}} />

          <div className="paragraph" style={{"-webkit-text-align":"center","text-align":"center"}}><strong><font color="#0e4361">​
          <Link to={'/terms-and-conditions'}>Terms and conditions.</Link>&nbsp;
          <Link to={'/privacy-policy'}>Privacy policy.</Link>&nbsp;
          <Link to={'/disclaimer'}>Disclaimer.</Link>.</font></strong><br /><strong><font color="#0e4361">SPORTZ COIN™ ​is a registered trademark to Focus Media UK, Switzerland and&nbsp;USA</font></strong><br /><strong><font color="#0e4361">Copyright © 2022 SPORTZ COIN. All rights reserved. Invest Responsibly.&nbsp;</font></strong><br /><br /></div>
        </div>
      </div>
    </div>
  )
}

export default Footer