import React, { useEffect } from 'react'
import SiteCanvas from './SiteCanvas';
import SiteWrapper from '../../components/siteWrapper';


function NFTMarketplace(props) {

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, [])

  return(
    <SiteWrapper>
      <SiteCanvas />
    </SiteWrapper>
  )
}


export default NFTMarketplace