import React from "react"
import NFT from "./views/ntf"
import Home from "./views/home"
import Contact from "./views/Contact"
import AboutUS from "./views/AboutUs"
import Disclaimer from "./views/Disclaimer"
import PrivacyPolicy from "./views/PrivacyPolicy"
import TermsConditions from "./views/TermsConditions"
import NFTMarketplace from "./views/NFT_Marketplace"
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom'
import Metaverse from "./views/Metaverse"

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={ <Home /> } />
        <Route exact path="/nfts" element={ <NFT /> } />
        <Route exact path="/contact" element={ <Contact /> } />
        <Route exact path="/about-us" element={ <AboutUS /> } />
        <Route exact path="/disclaimer" element={ <Disclaimer /> } />
        <Route exact path="/nft-marketplace" element={ <NFTMarketplace /> } />
        <Route exact path="/privacy-policy" element={ <PrivacyPolicy /> } />
        <Route exact path="/metaverse" element={ <Metaverse /> } />
        <Route exact path="/terms-and-conditions" element={ <TermsConditions /> } />
        <Route path="*" element={<div>404 Not found</div>}/>
      </Routes>
    </Router>
  )
}

export default App;
